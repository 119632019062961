<script setup>

</script>

<template>
  <div class="ip_root">ip记录</div>
</template>

<style scoped>

</style>