<script>

import {Key, User} from "@element-plus/icons-vue";
import {postFormData} from "@/utils/axios-instance";
import router from "@/router";

export default {
  computed: {
    Key() {
      return Key
    },
    User() {
      return User
    }
  },
  data() {
    return {
      input_account: '',
      input_pwd: '',
      btn_loading: false
    }
  }, methods: {


    postLogin() {
      this.btn_loading = true
      const data = {account: this.input_account, password: this.input_pwd};
      postFormData('/download_manager_api/login', data).then(response => {
        this.btn_loading = false
        if ('0' === response.data.errno) {
          router.push("/e4t7d8g7m62_h")
        }
      }).catch(e => {
        console.log(e)
        this.btn_loading = false
      })
    }

  }
}

</script>

<template>
  <div class="root_login">
    <div class="card_root">
      <div class="login_card">

        <h2 class="title">我爱加速后台管理</h2>

        <el-input
            v-model="input_account"
            style="width: 300px;margin-top: 10px"
            placeholder="请输入账号"
            :prefix-icon="User"
        />

        <el-input
            v-model="input_pwd"
            style="width: 300px;margin-top: 25px"
            type="password"
            placeholder="请输入密码"
            show-password
            :prefix-icon="Key"
        />

        <el-button type="primary" @click="postLogin" style="width: 300px;margin-top: 30px" :loading="btn_loading">登录
        </el-button>

      </div>
    </div>
  </div>
</template>

<style scoped>
.root_login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root_login .card_root {
  width: 380px;
  height: 300px;
  border: 1px solid #cccccc;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root_login .card_root .login_card {
  width: 300px;
  height: 265px;
}

.root_login .card_root .login_card .title {
  text-align: center;
}
</style>