<script>


import {Bell, ChatDotSquare, DataLine, Discount, Edit, Setting} from "@element-plus/icons-vue";
import router from "@/router";

export default {
  components: {Bell, ChatDotSquare, DataLine, Edit, Discount, Setting},
  data() {
    return {
      menuDefaultActive: 'statistics',
      logoUrl: require('@/assets/logo.png'),
    }
  }, methods: {
    logoClick() {
      router.push("/")
      this.menuDefaultActive = "statistics"
    },
    itemSelect(index) {
      router.push("/e4t7d8g7m62_h/" + index)
      this.menuDefaultActive = index
    }
  }
}
</script>

<template>
  <el-container>
    <el-aside width="200px">
      <div class="root_aside">

        <div class="logo" @click="logoClick">
          <el-image class="img" style="width: 35px; height: 35px" :src="logoUrl" fit="fill"/>
          <h2 class="title">我爱加速</h2>
        </div>

        <el-scrollbar style="border-right: 1px solid #e6e6e6;user-select: none">
          <el-menu :default-openeds="[]" style="border: 0" @select="itemSelect" :default-active="menuDefaultActive">

            <el-menu-item index="statistics">
              <el-icon>
                <DataLine/>
              </el-icon>
              数据概览
            </el-menu-item>

            <el-menu-item index="briefing">
              <el-icon>
                <ChatDotSquare/>
              </el-icon>
              公告通知管理
            </el-menu-item>


            <el-sub-menu index="2">
              <template #title>
                <el-icon>
                  <Edit/>
                </el-icon>
                用户使用记录
              </template>
              <el-menu-item index="analysis_record">解析记录</el-menu-item>
              <el-menu-item index="download_record">下载记录</el-menu-item>
              <el-menu-item index="ip_record">IP记录</el-menu-item>

            </el-sub-menu>

            <el-sub-menu index="3">
              <template #title>
                <el-icon>
                  <Discount/>
                </el-icon>
                Cookie管理
              </template>
              <el-menu-item index="cookie_normal">普通Cookie</el-menu-item>
              <el-menu-item index="cookie_svip">SVIP Cookie</el-menu-item>
            </el-sub-menu>

            <el-menu-item index="feedback">
              <el-icon>
                <Bell/>
              </el-icon>
              用户反馈
            </el-menu-item>

            <el-menu-item index="setting">
              <el-icon>
                <Setting/>
              </el-icon>
              设置
            </el-menu-item>

          </el-menu>
        </el-scrollbar>

      </div>
    </el-aside>
    <el-main style="padding: 0">
      <div class="root_main">
        <router-view></router-view>
      </div>
    </el-main>
  </el-container>
</template>

<style scoped>
.root_aside {
  height: calc(100vh - 81px);
}

.root_main {
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
}

.logo {
  width: calc(100% - 1px);
  border-right: 1px solid #e6e6e6;
  height: 77px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
}

.logo .img {
  margin-left: 14px;
}


.logo .title {
  margin-left: 6px;
  color: #333333;
  user-select: none;
}

</style>