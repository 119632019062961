<script setup>

</script>

<template>
  <div class="analysis_record_root">设置</div>
</template>

<style scoped>

</style>