<script setup>

</script>

<template>
  <div class="analysis_record_root">用户反馈</div>
</template>

<style scoped>

</style>