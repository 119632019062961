import axios from 'axios';
import FormData from 'form-data';
import router from "@/router";
import {ElMessage} from "element-plus";
import CookieManager from "@/utils/cookie_manager";

// 配置axios发送cookie
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
    baseURL: 'https://api.52speed.com'
});


axiosInstance.interceptors.request.use(config => {
    // 根据条件加入token-安全携带
    // config.headers['X-API-KEY'] = "123123123"
    return config;
}, error => {
    // 请求错误处理
    Promise.reject(error);
})


axiosInstance.interceptors.response.use(response => {
    if (response && response.status === 200) {
        // 如果响应状态码是401，重定向到登录页面
        if ("904" === response.data.errno) {
            CookieManager.clearAllCookies()
            router.push('/login').then(r => {
                console.log(r)
            });
        } else if ("901" === response.data.errno) {
            ElMessage.error(response.data.message)
        }
    }
    return response;
}, error => {

    return Promise.reject(error);
});

export function postFormData(url, data) {
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    return axiosInstance.post(url, formData);
}

export default axiosInstance;
