<script setup>

</script>

<template>
  <div class="normal_cookie_root">
    普通Cookie管理
  </div>
</template>

<style scoped>

</style>