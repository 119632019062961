<script>
import {LineChart} from 'vue-chart-3';
import {Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip} from 'chart.js';
import {postFormData} from "@/utils/axios-instance";

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip);

export default {
  components: {
    LineChart
  },
  data() {
    return {
      loadingState: false,
      statisticsData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false,
          position: 'nearest',
        }
      }
    }
  }, created() {
    this.loadingState = true
    postFormData('/download_manager_api/get_overview_statistics').then(response => {
      if ("0" === response.data.errno) {
        this.statisticsData = response.data.data
      }
      this.loadingState = false
    }).catch(e => {
      console.log(e)
      this.loadingState = false
    })
  }
}

</script>

<template>
  <div class="root_statistics" v-loading="loadingState">
    <div class="card_empty"></div>

    <!--基本数据-->
    <el-row :gutter="16" style="width: calc(100% - 14px);margin-bottom: 15px;margin-left: 8px;">
      <el-col :span="8">
        <el-card v-if="statisticsData.basisData" class="data_number_content" shadow="never">当前在线人数
          <b>{{ statisticsData.basisData.onLineUser }}</b> 人
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card v-if="statisticsData.basisData" class="data_number_content" shadow="never">用户总数
          <b>{{ statisticsData.basisData.userSumCount }}</b> 人
        </el-card>
      </el-col>
      <!--      <el-col :span="8">-->
      <!--        <el-card class="data_number_content" shadow="never">在线人数 <b>6666</b> 人</el-card>-->
      <!--      </el-col>-->
    </el-row>

    <!--统计图数据-->
    <el-card class="item_card" shadow="never" v-for="item in statisticsData.statisticsDataList" :key="item.cardTitle">

      <!--统计图标题-->
      <div class="card_title_box">
        <div class="card_title" :style="{'background-color':item.cardTitleBackGroundColor}">
          {{ item.cardTitle }}
        </div>
        <div class="card_sub_title">{{ item.cardSubTitle }}</div>
      </div>

      <!--数据汇总-->
      <div class="card_data">
        <el-row class="data_number_root">
          <el-card class="data_number_content" shadow="always" v-for="summaryItem in item.summary"
                   :key="summaryItem.startText">{{ summaryItem.startText }}&nbsp;<b>{{ summaryItem.value }}</b>&nbsp;
            {{ summaryItem.endText }}
          </el-card>
        </el-row>

        <!--图形显示-->
        <div class="data_photos_box">
          <LineChart style="width: 90%;height: 280px;" :chart-data="item.chartData"
                     :options="chartOptions"/>
        </div>
      </div>

    </el-card>

  </div>
</template>

<style scoped>
.root_statistics {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.card_empty {
  width: 100%;
  height: 16px;
}

.item_card {
  width: calc(100% - 30px);
  margin-bottom: 20px;
  margin-left: 15px;
}

.card_data {
  width: 100%;
}

.card_title_box {
  display: flex;
}

.card_title_box .card_title {
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  color: #FFFFFF;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.card_title_box .card_sub_title {
  font-size: 14px;
  color: #b0b0b0;
  margin-left: 12px;
  line-height: 35px;
}


.card_data .data_number_root {
  margin-top: 25px;
}

.card_data .data_number_root .data_number_content {
  width: 210px;
  height: 55px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_data .data_photos_box {
  width: 100%;
  margin-top: 20px;
}
</style>