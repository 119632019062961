import {createRouter, createWebHistory} from 'vue-router';
import PageLogin from '@/components/PageLogin.vue';
import PageHome from '@/components/PageHome.vue';
import NotFound from '@/components/NotFound.vue';
import PageStatistics from '@/components/page_home_child/PageStatistics.vue';
import PageCookieNormal from "@/components/page_home_child/PageCookieNormal.vue";
import PageDownloadRecord from "@/components/page_home_child/PageDownloadRecord.vue";
import PageCookieSenior from "@/components/page_home_child/PageCookieSenior.vue";
import PageSetting from "@/components/page_home_child/PageSetting.vue";
import PageBriefing from "@/components/page_home_child/PageBriefing.vue";
import PageAnalysisRecord from "@/components/page_home_child/PageAnalysisRecord.vue";
import PageIpRecord from "@/components/page_home_child/PageIpRecord.vue";
import PageFeedback from "@/components/page_home_child/PageFeedback.vue";

// 路由配置
const routes = [
    {
        path: '/',
        redirect: '/login' // 根路径重定向到 /home
    },
    {
        path: '/e4t7d8g7m62_h',
        component: PageHome,
        redirect: '/e4t7d8g7m62_h/statistics', // /home 默认显示 /home/statistics
        children: [
            {
                path: 'statistics', // 使用相对路径
                component: PageStatistics,
                meta: {title: '数据概览'}
            }, {
                path: 'briefing', // 公告管理
                component: PageBriefing,
                meta: {title: '公告通知管理'}
            }, {
                path: 'analysis_record', // 解析记录
                component: PageAnalysisRecord,
                meta: {title: '解析记录'}
            }, {
                path: 'download_record', // 下载记录
                component: PageDownloadRecord,
                meta: {title: '下载记录'}
            }, {
                path: 'ip_record', // 下载记录
                component: PageIpRecord,
                meta: {title: 'IP记录'}
            }, {
                path: 'feedback', // 下载记录
                component: PageFeedback,
                meta: {title: '用户反馈'}
            },
            {
                path: 'cookie_normal', // 普通Cookie管理
                component: PageCookieNormal,
                meta: {title: '普通Cookie管理'}
            },
            {
                path: 'cookie_svip', // SVIP Cookie管理
                component: PageCookieSenior,
                meta: {title: 'SVIP Cookie管理'}
            },
            {
                path: 'setting', // 设置
                component: PageSetting,
                meta: {title: '设置'}
            }
        ]
    },
    {
        path: '/login',
        component: PageLogin,
        meta: {title: '登录'}
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    }
];

// 创建并导出 Vue Router 实例
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
