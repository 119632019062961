<script>
import {postFormData} from "@/utils/axios-instance";

export default {
  data() {
    return {
      page_loading: false,
      table_loading: false,
      page: 1,
      page_count: 1,
      item_count: 0,
      cutter_page: 1,
      tableData: []
    }
  }, created() {
    this.page_loading = true
    let fromData = {'page': this.page}
    postFormData('/download_manager_api/get_download_record', fromData).then(response => {
      let resp_data = response.data
      if ("0" === resp_data.errno) {
        this.item_count = resp_data.data.item_count
        this.page_count = resp_data.data.page_count
        this.tableData = resp_data.data.list
      }
      this.page_loading = false
    }).catch(e => {
      console.log(e)
      this.page_loading = false
    })
  }, methods: {

    //分页器发生变化
    pageChange(page) {
      this.tableData = []
      this.table_loading = true
      let fromData = {'page': page}
      postFormData('/download_manager_api/get_download_record', fromData).then(response => {
        let resp_data = response.data
        if ("0" === resp_data.errno) {
          this.item_count = resp_data.data.item_count
          this.page_count = resp_data.data.page_count
          this.tableData = resp_data.data.list
        }
        this.table_loading = false
      }).catch(e => {
        console.log(e)
        this.table_loading = false
      })
    },

    //获取下载链接
    getDownloadLink(index) {
      console.log(index)
    },

    getLinkInfo(index) {
      console.log(index)
    }

  }
}
</script>

<template>
  <div class="download_record_root" v-loading="page_loading">
    <div class="empty"></div>
    <div class="option_box">
      <h3>下载记录</h3>
    </div>
    <div class="empty"></div>
    <div class="table_box">
      <el-table v-loading="table_loading" border :data="tableData"
                style="width: calc(100% - 16px);margin-left: 8px;margin-top: 16px;height: calc(100% - 24px);">
        <el-table-column type="selection" width="38"/>
        <el-table-column prop="format_time" label="操作时间" width="140"/>
        <el-table-column prop="share_link" label="分享链" width="250"/>
        <el-table-column prop="file_path" label="路径" width="400"/>
        <el-table-column prop="file_size" label="文件大小" width="110"/>
        <el-table-column prop="bind_ip" label="操作者ip" width="320"/>
        <el-table-column prop="" label="相关操作">
          <template #default="scope">
            <el-button size="small" @click="getLinkInfo(scope.$index)">获取链接详细</el-button>
            <el-button size="small" @click="getDownloadLink(scope.$index)">获取下载链接</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="empty"></div>
    <div class="table_page_box">
      <el-pagination style="margin-left: 26px" background layout="prev, pager, next" :default-current-page="cutter_page"
                     :page-count="page_count" @change="pageChange"/>
    </div>
  </div>
</template>

<style scoped>

.download_record_root {
  width: 100%;
  height: 100%;
}

.empty {
  width: 100%;
  height: 16px;
}

.option_box {
  width: calc(100% - 32px);
  height: 50px;
  margin-left: 16px;
  background-color: #FFFFFF;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  line-height: 15px;
}

.option_box h3 {
  margin-left: 16px;
}

.table_box {
  width: calc(100% - 32px);
  height: calc(100% - 185px);
  margin-left: 16px;
  background-color: #FFFFFF;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
}

.table_page_box {
  width: calc(100% - 32px);
  height: 64px;
  margin-left: 16px;
  background-color: #FFFFFF;
  border: 1px solid #e4e7ed;
  display: flex;
  align-items: center;
  justify-content: start;
}
</style>