import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axiosInstance from './utils/axios-instance';

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || '我爱加速后台管理';
    next();
});

const app = createApp(App);

app.use(router);
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$axiosApi = axiosInstance;

app.mount('#app');