<script setup>

</script>

<template>
<div class="root_not_found">
  404
</div>
</template>

<style scoped>
.root_not_found{
  width: 100%;
  height: 100%;
}
</style>