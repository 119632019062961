<script>
import {postFormData} from "@/utils/axios-instance";
import {ElMessage} from "element-plus";

export default {
  data() {
    return {
      loadingState: false,
      clientBriefing: {
        type: 1,
        clientBriefingIsOpen: false,
        clientBriefingText: '',
        clientBriefingTextOld: '',
        switchBtnLoading: false,
        submitLoading: false,
        isAllowSubmit: false,
      }
    }
  }, created() {
    this.loadingState = true
    postFormData('/download_manager_api/get_briefing').then(response => {
      let resp_data = response.data
      let _this = this
      if ("0" === resp_data.errno) {
        resp_data.data.forEach(function (item) {
          console.log(item)
          switch (item['type']) {
            case '1':
              _this.clientBriefing.clientBriefingIsOpen = (item.state === '1')
              _this.clientBriefing.clientBriefingText = item.value
              _this.clientBriefing.clientBriefingTextOld = item.value
              break
          }
        });
      }
      this.loadingState = false
    }).catch(e => {
      console.log(e)
      this.loadingState = false
    })

    // let _this = this

    // setInterval(() => {
    //   console.log(_this.clientBriefing.clientBriefingText)
    //   console.log(_this.clientBriefing.clientBriefingTextOld)
    //   console.log(_this.clientBriefing.clientBriefingText === _this.clientBriefing.clientBriefingTextOld)
    // }, 2000);

  }, methods: {

    //提交公告
    postBriefing(data) {
      if (data.clientBriefingText.replace(/[\r\n]+/g, "").length !== 0) {
        this.clientBriefing.submitLoading = true
        let open_state = data.clientBriefingIsOpen ? 1 : 0
        let paramData = {type: data.type, open_state, value: data.clientBriefingText}
        postFormData('/download_manager_api/set_briefing', paramData).then(response => {
          if ("0" === response.data.errno) {
            ElMessage.success("提交修改成功")
            this.clientBriefing.clientBriefingTextOld = data.clientBriefingText
            this.inputChange()
          }
          this.clientBriefing.submitLoading = false
        }).catch(e => {
          console.log(e)
          this.clientBriefing.submitLoading = false
        })
      } else {
        ElMessage.error("请输入公告")

      }
    },

    postBriefingSwitch(type, state) {
      let paramData = {type: type, open_state: state}
      postFormData('/download_manager_api/set_briefing', paramData).then(response => {
        if ("0" === response.data.errno) {
          console.log(response)
        }
        this.clientBriefing.switchBtnLoading = false
      }).catch(e => {
        console.log(e)
        this.clientBriefing.switchBtnLoading = false
      })
    },

    handleSwitchClick() {
      this.clientBriefing.switchBtnLoading = true
      let state = (!this.clientBriefing.clientBriefingIsOpen) ? '0' : '1'
      this.postBriefingSwitch('1', state)
      return false
    },

    inputChange() {
      if (this.clientBriefing.clientBriefingText.replace(/[\r\n]+/g, "") === this.clientBriefing.clientBriefingTextOld.replace(/[\r\n]+/g, "")) {
        this.clientBriefing.isAllowSubmit = false
      } else {
        this.clientBriefing.isAllowSubmit = true
      }
    }

  }
}
</script>

<template>
  <div class="briefing_root" v-loading="loadingState">

    <div class="empty"></div>

    <div class="card_briefing" v-if="loadingState === false" v-loading="clientBriefing.submitLoading">
      <div class="card_title">
        <span>客户端公告通知</span>
      </div>

      <div class="switch_box">
        <el-text class="mx-1" size="large">公告开启状态：</el-text>
        <el-switch
            v-model="clientBriefing.clientBriefingIsOpen"
            active-text="开启"
            size="large"
            inline-prompt
            inactive-text="关闭"
            :loading="clientBriefing.switchBtnLoading"
            @click.prevent="handleSwitchClick"
        />
      </div>


      <div class="client_briefing_info"
           style="width: calc(100% - 32px);display: flex;padding-bottom: 25px;margin-top: 16px">

        <div class="edit" style="width: 430px;margin-left: 32px;height: 380px;">
          <el-text class="mx-1" size="large">编辑客户端公告：</el-text>
          <div class="edit_client_briefing_box" style="height: 280px;margin-top: 12px">
            <el-input
                v-model="clientBriefing.clientBriefingText"
                maxlength="1024"
                rows="12"
                style="width: 430px"
                placeholder="请输入公告（html）"
                show-word-limit
                type="textarea"
                @input="inputChange"
                resize="none"/>
          </div>
          <el-button type="primary" style="width: 100px;" @click="postBriefing(clientBriefing)"
                     :disabled="!clientBriefing.isAllowSubmit">
            提交修改
          </el-button>
        </div>
        <div class="preview" style="margin-left: 64px">
          <el-text class="mx-1" size="large">预览：</el-text>
          <div class="card_client_briefing_preview" v-html="clientBriefing.clientBriefingText"></div>
        </div>
      </div>
    </div>


  </div>
</template>

<style scoped>

.empty {
  width: 100%;
  height: 16px;
}

.card_title {
  font-weight: bold;
  color: #333333;
  font-size: 18px;
  line-height: 60px;
  margin-left: 32px;
  user-select: none;
  display: flex;
  align-items: center;
}

.switch_box {
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 32px;
  line-height: 40px;
  margin-top: 12px;
}

.card_briefing {
  width: calc(100% - 32px);
  margin-left: 16px;
  background-color: #FFFFFF;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  min-width: 960px
}

.card_briefing .edit_client_briefing_box {
  width: 300px;
  height: 50px;
}

.card_briefing .card_client_briefing_preview {
  width: 400px;
  min-height: 230px;
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #e4e7ed;
  background-color: #FFFFFF;
  font-size: 14px;
  margin-top: 12px;
}
</style>